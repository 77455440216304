import React from "react";

const CurrentPage = ({ pageContext: { humanPageNumber, numberOfPages } }) => (
  <React.Fragment>
    {!humanPageNumber ? (
      <React.Fragment></React.Fragment>
    ) : (
      <small>
        Seite {humanPageNumber}
        {numberOfPages && ` von ${numberOfPages}`}
      </small>
    )}
  </React.Fragment>
);

export default CurrentPage;
