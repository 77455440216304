/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import Link from "../../../components/Link";
import Pagination from "../../../components/Pagination";
import CurrentPage from "../../../components/CurrentPage";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Seo from "../../../components/Seo";
import { formatLocalDate } from "../../../utils/dateTime";
import { BaseStyles, Card } from "theme-ui";
import { keyframes } from "@emotion/react";

const cardEntryAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-100%, 0) scale(0.3);
  }
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1.0);
  }
`;

const nthChildAnimations = Array.from({ length: 20 }, (_, i) => ({
  [`:nth-of-type(${i + 1})`]: {
    animationDelay: `${50 * i}ms`,
  },
})).reduce((a, b) => Object.assign(a, b), {});

const ArticleTemplate = ({
  excerpt,
  fields,
  frontmatter: { title, releasedAt },
  ...props
}) => (
  <Link
    to={fields.slug}
    variant="card"
    sx={{
      animation: `${cardEntryAnimation} .5s ease-out`,
      animationFillMode: "backwards",
      ...nthChildAnimations,
    }}
    {...props}
  >
    <Card tag="article" variant="default">
      <header>
        <h3>{title}</h3>
      </header>
      <main>
        <p>{excerpt}</p>
      </main>
      <footer sx={{ variant: "cards.footer" }}>
        <span>
          veröffentlicht am{" "}
          <time dateTime={releasedAt}>{formatLocalDate(releasedAt)}</time>
        </span>
      </footer>
    </Card>
  </Link>
);

const PaginationTemplate = ({ data, pageContext, location }) => (
  <>
    <Seo
      title="Neuigkeiten"
      pathname={location.pathname}
      description="Erfahre alle Neuigkeiten über die Feuerwehr Arborn."
    />

    <Layout>
      <BaseStyles>
        <Breadcrumbs names={["Neuigkeiten"]} location={location} />
        <h2>Neuigkeiten</h2>
        <CurrentPage pageContext={pageContext} />
        <div
          sx={{
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(360px, 100%), 1fr))",
            gridGap: 4,
          }}
        >
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <ArticleTemplate {...node} key={node.id} />
          ))}
        </div>
        <Pagination path="/neuigkeiten" {...pageContext} />
      </BaseStyles>
    </Layout>
  </>
);

export default PaginationTemplate;

export const articlePaginationQuery = graphql`
  query ArticlePagination($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateName: { eq: "news/articles" } } }
      sort: { fields: frontmatter___releasedAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            releasedAt
          }
        }
      }
    }
  }
`;
