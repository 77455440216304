/** @jsxImportSource theme-ui */
import React from "react";
import Link from "./Link";

const PrevNextLink = ({ text, link }) => (
  <li>
    {!link ? (
      <div sx={{ variant: "pagination.item" }}>{text}</div>
    ) : (
      <Link to={link} sx={{ variant: "pagination.item" }}>
        {text}
      </Link>
    )}
  </li>
);

const PrevLink = (props) => <PrevNextLink text={"‹"} {...props} />;
const NextLink = (props) => <PrevNextLink text={"›"} {...props} />;

const PaginationComponent = ({
  path,
  humanPageNumber,
  numberOfPages,
  previousPagePath,
  nextPagePath,
}) => {
  const numberedLinks = [];
  for (let i = 1; i < numberOfPages + 1; i++) {
    const url = i < 2 ? `${path}` : `${path}/${i}`;
    const isActive = humanPageNumber === i;
    numberedLinks.push(
      <li key={`pagination-item-${i}`}>
        {isActive ? (
          <div sx={{ variant: "pagination.activeItem" }}>
            {i} <span sx={{ variant: "srOnly" }}>(aktuelle Seite)</span>
          </div>
        ) : (
          <Link to={url} sx={{ variant: "pagination.item" }}>
            {i}
          </Link>
        )}
      </li>
    );
  }
  return (
    numberOfPages > 1 && (
      <ul sx={{ variant: "pagination" }}>
        <PrevLink link={previousPagePath} />
        {numberedLinks}
        <NextLink link={nextPagePath} />
      </ul>
    )
  );
};

export default PaginationComponent;
